.dropdown-component-container {
    width: auto;
    display: flex;
}

.dropdown-component {
    width: 100%;
    background: linear-gradient(135deg, rgba(26, 26, 26, 1) 0%, rgba(43, 43, 43, 1) 100%);
    cursor: pointer;
    text-align: left;
    z-index: 2;
    height: 4vh;
    color: white;
    border-radius: 0.5vw;
    border: var(--maind) 0.1vw solid;
    font-weight: bold;
    box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 0.5);
    padding: 0.2vw;
}

.dropdown-component span {
    font-size: 0.83vw;
    display: flex;
}

.dropdown-component-list {
    font-size: 0.83vw;
    width: 100%;
    color: white;
    border: var(--maind) 0.1vw solid;
    box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.1);
    max-height: 15vw;
    overflow: hidden;
    z-index: 100;
    height: auto;
    background: linear-gradient(135deg, rgba(26, 26, 26, 1) 0%, rgba(43, 43, 43, 1) 100%);
    border-radius: 0.5vw;
    box-shadow: 0 0.25vw 1vw rgba(0, 0, 0, 1);
}

.dropdown-component-list p {
    width: 100%;
    color: white;
    margin: 0;
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    border: #00000000 0.1vw solid;
}

.dropdown-component-list p:hover {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.5vw;
    border: 0.1vw solid var(--bg);
    color: var(--main);
    box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 0.5);
}

.dropdown-component-item {
    cursor: pointer;
}

.dropdown-placeholder {
    display: flex;
    font-size: 0.83vw;
    color: gray;
}