.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  width: 100%;
}

.overlay > div:first-child {
  margin-left: calc((100vw / 12) * 1);
  margin-right: calc((100vw / 12) * 1);
}

.overlay-container {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--bg1);
  border-radius: 0.5vw;
  width: 50%;
  max-height: 85vh;
  overflow: hidden;
  box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 1);
  padding: 0.5vw;
  margin-top: 3%;
  backdrop-filter: blur(0.1vw);
}

.overlay-content {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--bg);
  padding: 1vw;
  border-radius: 0.5vw;
  width: auto;
  min-width: 50%;
  height: 100%;
  overflow: auto;
  box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 1);
}

.overlay-content::-webkit-scrollbar-track {
  background-color: var(--bg);
}

.overlay-close {
  position: absolute;
  top: 0.5vw;
  right: 0.5vw;
  background: none;
  border: none;
  font-size: 2vw;
  border-radius: 0.5vw;
  padding: 0.1vw;
  line-height: 2vw;
  cursor: pointer;
  font-family: EA Font;
  font-weight: bolder;
  color: var(--maindd);
  z-index: 99999;
}

.overlay-close:hover {
  color: var(--mainb);
}

.overlay-close:active {
  transform: scale(0.99);
  color: var(--maind);
}

.overlay-stats-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1vw;
  align-items: center;
}

.overlay-stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  height: fit-content;
  border-radius: 0.5vw;
  background-color: var(--bg1);
  box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 1);
}

.overlay-stats p {
  font-size: 1.2vw;
  font-weight:500;
  margin-bottom: 0.8vw;
}

.overlay-font {
  font-family: EA Font;
}

.overlay-matches-container {
  display: flex;
  flex-direction: column;
  background-color: var(--bg);
  border-radius: 0.5vw;
  padding: 0.5vw;
  margin: 0.5vw;
  h3 {
    margin-bottom: 0;
    font-size: 2vw;
  }
}

.overlay-matches-container h3 {
  text-align: center;
}

.overlay-matches {
  background-color: var(--bg1);
  border-radius: 0.5vw;
  padding: 0.5vw;
  margin: 0.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.7);
}

.overlay-matches:hover {
  cursor: pointer;
  transform: scale(1.01);
  box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 1);


  .overlay-match-title {
    color: var(--mainb);
  }
}

.overlay-matches:active {
  transform: scale(0.99);
  box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 1);

  .overlay-match-title {
    color: var(--maind);
  }
}

.overlay-matches.selected {
  box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 1);
}

.overlay-match-title {
  font-size: 1vw;
  font-weight: bolder;
  margin: 0;
}

.match-stats {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  p {
    font-size: 0.8vw;
  }
}

.overlay-matches p {
  font-weight: bolder;
  margin: 0;
}

.stats-team-image {
  width: 2vw;
  height: 2vw;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}

.overlay-moreinfo {
  color: var(--main);
  font-weight: bolder;
  cursor: pointer;
  width: auto;
  height: auto;
}

.overlay-moreinfo:hover {
  color: var(--mainb);
}

.overlay-moreinfo:active {
  transform: scale(0.99);
  color: var(--maind);
}