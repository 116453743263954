.frontpage-container {
    display: flex;
    position: relative;
    flex-direction: row;
    height: 100%;
}

.frontpage-main-content {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
}