.teamWinner-container {
    display: flex;
    flex-direction: column;
    background: var(--bg1);
    border-radius: 0.5vw;
    padding: 0.5vw;
    height: 50%;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
    backdrop-filter: blur(0.1vw);
    z-index: 1;
}

.teamWinner-title {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
}

.teamWinner-box {
    font-size: 1.2vw;
    display: flex;
    flex-wrap: nowrap;
    text-align: left;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    min-width: 6vw;
    max-width: 12vw;
    height: auto;
    background-color: rgba(0, 0, 0, 0);
    border: var(--maindd) solid 0.1vw;
    color: white;
    border-radius: 0.5vw;
    box-shadow: inset 0 0.2vw 0.4vw rgba(0, 0, 0, 1);
    margin-left: 0.5vw;
    min-height: 70%;
}

.teamWinner-info {
    display: flex;
    flex-direction: row;
    position:flex-end;
    flex-direction: none;
    justify-content: flex-end;
    align-items: center;
    width: auto;
    height: auto;
}

.teamWinner-title-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.teamWinner-info:last-child {
    color: var(--lightgreen);
}

.winnerTeamBox {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 0;
    background-color: var(--bg);
    border-radius: 0.5vw;
    padding: 0.5vw;
    overflow: hidden;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0.2vw 0.4vw rgba(0, 0, 0, 1);
}

.winnerTeam-eventname {
    font-size: 1.2vw;
    padding: 0.25vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    border-radius: 0.5vw;
    background: var(--bg1);
    border: 0.1vw solid #000000;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
}

.winnerTeam-card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-bottom: 1vw;
}

.points-box {
    position: relative;
    text-align: left;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    width: 90%;
    background-color: rgba(0, 0, 0, 0);
    border: rgba(223, 143, 23, 0.2) solid 0.1vw;
    color: white;
    font-size: 0.8vw;
    cursor: pointer;
    border-radius: 0.5vw;
    box-shadow: inset 0 0.2vw 0.4vw rgba(0, 0, 0, 0.5);

    justify-content: center;
    align-items: center;
    bottom: 0.5vw;
}

.winnerTeam-card-points {
    margin: 0;
    text-align: center;
    align-content: center;
    font-weight: bolder;
}

.points-value {
    margin-right: 0.25vw;
  }