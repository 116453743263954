.profile-options {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.profile-information-container {
  display: flex;
  flex-direction: column;
  background: var(--bg1);
  border-radius: 0.5vw;
  padding: 0.5vw;
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
  box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 1);
  backdrop-filter: blur(0.1vw);
  z-index: 1;
  width: 100%;
  justify-content: space-between;
}

.profile-information {
  background: var(--bg);
  padding: 0.5vw;
  border-radius: 0.5vw;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  box-shadow: inset 0 0 0.5vw #000000;
  h6 {
    font-size: 0.9vw;
    margin-bottom: 0.5vw;
  }
}

.profile-stats-main-container {
  display: flex;
  flex-direction: column;
  background: var(--bg1);
  border-radius: 0.5vw;
  padding: 0.5vw;
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
  box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 1);
  backdrop-filter: blur(0.1vw);
  z-index: 1;
  width: 100%;
  justify-content: space-between;
}

.profile-stats-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.profile-information-section {
  margin-top: 0.5vw;
}

.profile-information-section h6 {
  color: white;
  margin-left: 0.5vw;
  font-size: large;
}

.profile-information-section h6:first-of-type {
  margin-left: 0;
}

.profile-stats-table {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border-radius: 0.5vw;
  border-collapse: collapse;
  box-shadow: inset 0 0 0.5vw #000000;
  overflow: hidden;
}

.profile-stats-table th,
.profile-stats-table td {
  font-size: 0.9vw;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 0.5vw 0.6vw;
  flex-wrap: nowrap;
  text-align: left;
  border-bottom: 0.1vw solid var(--mainb);
}

.profile-stats-table tr {
  display: flex;
  flex-direction: row;
}

.profile-stats-table tr:last-child {
  td {
    border-bottom: none;
  }
}

.profile-stats-table th {
  color: white;
  font-weight: bold;
  font-size: 1.4vw;
}

.profile-stats-table-container {
  display: flex;
  background: var(--bg);
  border-radius: 0.5vw;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 50%;
}

.profile-stats-table-container:first-of-type {
  margin-right: 0.3vw;
}

.profile-stats-table-container:last-of-type {
  margin-left: 0.3vw;
}