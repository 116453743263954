.leaderbord-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 0.5vw;
    margin-top: 0.5vw;
}

.leaderboard-title {
    font-family: EA Font;
    color: white;
    font-weight: normal;
    margin: 0;
    margin-right: 5vw;
    width: 100%;
}

.leaderboard-text {
    color: white;
    font-weight: bolder;
    margin: 0;
    margin-left: 2vw;
    padding: 0.5vw;
}

.leaderboard-text:hover {
    color: var(--main);
    cursor: pointer;
}

.leaderboard-desc-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5vw;
    padding-left: 1vw;
    padding-right: 1vw;
    background: var(--bg-linear);
    border-radius: 0.5vw;
    margin-bottom: 0.5vw;
    box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.5);
    /* Reduced box shadow */
}

.leaderboard-desc-container h4 {
    font-size: 1.2vw;
    font-family: EA Font;
    text-align: center;
    margin: 0.5vw;
}

.leaderboard-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 0.5vw;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0.5vw;
    box-shadow: inset 0 0 0.5vw #000000;
    /* Reduced box shadow */
    background: radial-gradient(rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 100%);
}

.leaderboard-entry-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5vw;
    background: var(--bg-linear);
    border-radius: 0.5vw;
    margin-bottom: 0.5vw;
    border: 0.1vw solid transparent;
    cursor: pointer;
    height: calc(120vh/20 - 0.5vw);
    box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.5);
    /* Reduced box shadow */
    transition: transform 0.2s, box-shadow 0.2s, border 0.2s;
    /* Added transition for hover effects */
}

.leaderboard-entry-container.big {
    height: calc(59vh/5) !important;
}

.leaderboard-entry-container.isUpcoming {
    background: linear-gradient(145deg,
            color-mix(in srgb, var(--maind) 20%, transparent) 0%,
            color-mix(in srgb, var(--maind) 10%, transparent) 10%,
            var(--bg1) 20%);
    border: 0 solid var(--main);
}

.leaderboard-entry-container.isActive {
    background: linear-gradient(145deg,
            color-mix(in srgb, var(--mainb) 20%, transparent) 0%,
            color-mix(in srgb, var(--mainb) 10%, transparent) 10%,
            var(--bg1) 20%);
    border: 0 solid var(--main);
}

.leaderboard-entry-container.isLive {
    background: linear-gradient(145deg, rgba(255, 0, 0, 0.2) 0%, rgba(255, 0, 0, 0.1) 10%, var(--bg1) 20%);
    border: 0 solid var(--main);
}

.leaderboard-entry-container .image {
    margin: 0;
    align-self: center;
    height: calc(120vh/10 - 1.6vw);
    width: auto;
}

.leaderboard-entry-container p {
    font-size: 0.83vw;
    font-weight: 500;
    margin: 0;
    text-align: center;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
}

.leaderboard-entry-container:hover {
    transform: translateY(-0.1vw);
    /* Use translate instead of scale */
    box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.5);
    /* Reduced box shadow */
    border: 0.1vw solid var(--main);
    /* Added border on hover */
}

.leaderboard-entry-container:last-child {
    margin-bottom: 0;
}

.highlight p {
    color: var(--main);
    text-shadow: 0 0 0.1vw var(--maindd);
}

.pagination {
    flex-grow: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5vw;
    background-color: var(--bg1);
    border-radius: 0.5vw;
    margin-top: 0.5vw;
    bottom: 0;
    box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.5);
    /* Reduced box shadow */
}

.pagination button {
    font-size: 1.3vw;
}

.pagination button:disabled {
    background-color: var(--bg);
    cursor: not-allowed;
}

.pagination button:disabled:active {
    transform: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.pagination button {
    width: 40%;
}

.pagination span {
    font-size: 1.3vw;
    font-weight: 500;
}

.event-desc-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5vw;
    padding-left: 1vw;
    padding-right: 1vw;
    border-radius: 0.5vw;
    background-color: var(--bg1);
    box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.5);
    /* Reduced box shadow */
    margin-bottom: 0.5vw;
    margin-top: 0.5vw;
}

.event-desc-container p {
    font-size: 1vw;
    font-family: EA Font;
    margin: 0;
    text-align: center;
}

.event-entry-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5vw;
    border-radius: 0.5vw;
    background-color: var(--bg1);
    box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.5);
    /* Reduced box shadow */
    margin-top: 0.5vw;
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.2s, box-shadow 0.2s, border 0.2s;
    /* Added transition for hover effects */
}

.event-entry-container p {
    font-size: 0.83vw;
    font-weight: 500;
    margin: 0;
    text-align: center;
}

.event-entry-container:hover {
    transform: translateY(-0.1vw);
    /* Use translate instead of scale */
    box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.5);
    /* Reduced box shadow */
    border: 0.1vw solid var(--main);
    /* Added border on hover */
}

.leaderboard-scroll-container {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0.5vw;
    border-radius: 0.5vw;
    box-shadow: inset 0 0 0.5vw #000000;
    /* Reduced box shadow */
}

/* Status text styles */
.status-text {
    font-family: EA Font;
    font-weight: 800;
    margin: 0 !important;
    margin-left: 1.5vw !important;
    font-size: 1.2vw !important;
}

.live-text {
    color: red;
}

.active-text {
    color: var(--mainb)
}

.upcoming-text {
    color: var(--maindd);
}