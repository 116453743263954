@font-face {
    font-family: EA Font;
    src: url(../assets/EASPORTS15.ttf);
}

.welcome-container {
    width: 100%;
    height: 100vh;
    margin: 0;
    overflow: hidden;
}

.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.5);
}

.content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-container {
    border-radius: 0.5vw;
    box-shadow: 0 0 1vw rgba(0, 0, 0, 0.4);
    padding: 0.5vw 1vw;
    transition: transform 0.2s;
    width: 27vw;
    height: auto;
    text-align: center;
    background-color: rgba(31, 31, 31, 0.692);
    backdrop-filter: blur(0.1vw);
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
}

.title-welcome {
    font-family: EA Font;
    color: var(--main);
    font-weight: normal;
    font-size: 2.5vw;
    text-shadow: 0 0 1vw var(--maindd);
    margin: 0;
}

.login-label {
    display: block;
    width: 100%;
    margin-top: 0.5vw;
    margin-bottom: 0.25vw;
    text-align: left;
    color: var(--maind);
    font-weight: bold;
    font-size: 0.8vw;
}


.input-field {
    display: block;
    width: 100%;
    margin-bottom: 0.5vw;
    padding: 0.5vw;
    box-sizing: border-box;
    border: 0 solid #ddd;
    border-radius: 0.5vw;
    box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 1);
    font-size: 0.82vw;
}

.input-field:focus {
    outline: none;
    border: 0 solid var(--main);
}

.submit-button {
    padding: 0.75vw;
    border-radius: 0.5vw;
    margin-top: 0.75vw;
    margin-bottom: 0.75vw;
    border: none;
    color: white;
    cursor: pointer;
    background: var(--main);
    width: 100%;
    font-size: 1vw;
    font-weight: bold;
}

.wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.error {
    color: red;
    font-weight: bold;
    font-size: 0.8vw;
    margin: 0.5vw;
    margin-bottom: 0;
}

.success {
    color: green;
    font-weight: bold;
    margin: 0.5vw;
    margin-bottom: 0;
}

.switch {
    text-decoration: none;
    cursor: pointer;
    font-size: 0.8vw;
    margin: 0;
    color: var(--main);
}

.switch:hover {
    color: var(--mainb);
}

.switch:active {
    color: var(--maind);
}