.fantasyTeamView-content {
  display: flex;
  position: relative;
  padding: 1vw;
  border-radius: 0.5vw;
  width: 100%;
  height: 100%;
  max-height: 80vh;
  background: var(--bg);
  overflow: hidden;
  box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 1);
  flex-direction: column;
  justify-content: center;
  padding: 0.5vw;
}

.overlay-content-center {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fantasyTeamView-close-button {
  background: none;
  border: none;
  font-size: 0.1rem;
  position: absolute;
  top: 0.5vw;
  right: 0.5vw;
  cursor: pointer;
}

.cards-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
}

.card-hoverable {
  height: 90%;
}

.card-hoverable:hover {
  cursor: pointer;
  transition: transform 0.2s;
  transform: scale(1.05);
}