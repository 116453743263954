.market-container {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    margin-top: 0.5vw;
    box-sizing: border-box;
    overflow: hidden;
}

.market-filter {
    display: flex;
    flex-direction: column;
    position: sticky;
    /* Make it sticky */
    top: 4.011vw;
    /* Stick to the top of the container */
    width: 20%;
    height: 100%;
    background: var(--bg-linear);
    border-radius: 0.5vw;
    padding: 0.5vw;
    overflow-y: auto;
    overflow-x: hidden;
    backdrop-filter: blur(0.1vw);

}

.market-filter-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5vw;

    h1 {
        font-size: 2.1vw !important;
    }
}

.market-content-container {
    display: flex;
    flex-direction: column;
    width: 79%;
    height: 100%;
    position: relative;
}

.market-content {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: radial-gradient(rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 100%);
    border-radius: 0.5vw;
    padding: 0.5vw;
    padding-bottom: 0;
    position: relative;
    box-shadow: inset 0 0 0.5vw #000000;
    backdrop-filter: blur(0.1vw);
}

.market-listing {
    flex-wrap: wrap;
    flex: 1 1 20%;
    /* Each card takes up 20% of the container width */
    margin: 0.5vw;
    display: flex;
    flex-direction: row;
    /* Ensure items are in a row */
    align-items: center;
    justify-content: center;
    /* Align items vertically centered */
    border-radius: 0.5vw;
    font-weight: normal;
    cursor: pointer;
    width: calc(20% - 1.5vw);
    /* Set the width to 50% minus the gap */
    margin: auto;
    /* Add some space to the right */
    transform: scale(var(--scale-factor, 1));
    /* Use CSS variable for scaling */
}

.market-listing-price {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2vw;
    font-weight: bold;
    width: auto;
    height: auto;
    color: #ccc;
    text-align: center;

    p {
        margin: 0;
        margin-left: 0.25vw;
    }
}

.market-playername {
    text-align: center;
    font-size: 1.5vw;
    text-transform: uppercase;
    font-weight: 700;
    color: white;
    margin-bottom: 0.25vw;
}

.search-suggestions {
    position: relative;
}

.market-search {
    padding: 0.5vw;
}

.market-searchbar {
    font-size: 0.9vw;
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 4vh;
    background: linear-gradient(135deg, rgba(26, 26, 26, 1) 0%, rgba(43, 43, 43, 1) 100%);
    color: white;
    border-radius: 1vw;
    padding: 0.5vw;
    border: var(--maind) 0.1vw solid;
    font-weight: bold;
    box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 0.5);
}

.market-searchbar:focus {
    outline: var(--bg1) 0.1vw solid;
}

.suggestions-list {
    font-size: 0.9vw;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: auto;
    overflow-y: hidden;
    overflow-x: hidden;
    background: linear-gradient(135deg, rgba(26, 26, 26, 1) 0%, rgba(43, 43, 43, 1) 100%);
    border-radius: 0.5vw;
    padding-top: 0.5vw;
    top: 0.5vw;
    border: var(--maind) 0.1vw solid;
}

.suggestions-list p {
    width: 100%;
    font-weight: bold;
    color: white;
    margin: 0;
    padding-bottom: 0.2vw;
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    border: #00000000 0.1vw solid;
}

.suggestions-list p:first-child {
    margin-top: 0.8vw;
}

.suggestions-list p.selected-suggestion,
.suggestions-list p:hover {
    color: var(--main);
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.5vw;
    box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 0.5);
    border: 0.1vw solid var(--bg);
}

.checkbox {
    width: 45%;
}

.checkbox-wrapper {
    width: auto;
    margin: 0;
    display: flex;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    user-select: none;

    &>* {
        margin: 0.5vw 0.5vw;
    }
}

.market-checkbox-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;

}

.market-checkbox-group .checkbox-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 0;
}

.checkbox-row:first-child {
    margin-bottom: 0.5vw;
}

.checkbox-input {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 0;
}

.checkbox-input:checked+.checkbox-tile {
    border-color: var(--main);
    box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.1);
    color: var(--main);
    border: 0.1vw solid var(--maind);
    box-shadow: 0 0 0.25vw var(--maind);
}

.checkbox-input:checked+.checkbox-tile .checkbox-icon,
.checkbox-input:checked+.checkbox-tile .checkbox-label {
    color: var(--main);
}

.checkbox-input:checked .checkbox-tile {
    border-color: var(--main);
    box-shadow: 0 0.25vw 0.5vw rgba(#000, 0.1);
    color: var(--main);
    border: 0.1vw solid var(--maind);
    box-shadow: 0 0 0.25vw var(--maind);
}

.checkbox-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 1.7vw;
    border-radius: 0.5vw;
    border: 0.1vw solid var(--maind);
    background: linear-gradient(135deg, rgba(26, 26, 26, 1) 0%, rgba(43, 43, 43, 1) 100%);
    box-shadow: 0 0.25vw 0.5vw rgba(#000, 0.1);
    cursor: pointer;
    position: relative;
    box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 0.5);



    &:hover {
        border-color: var(--mainb);
        scale: 1.02;
    }

    &:active {
        border-color: var(--maind);
        scale: 0.98;
    }
}

.checkbox-label {
    font-size: 0.82vw;
    font-weight: bold;
    color: #707070;
    text-align: center;
}

.market-boost-search-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10%;
    justify-content: space-between;
}

.market-boost-search {
    width: 45%;
}

.market-boost-tier-dropdown {
    width: 30%;
}

.market-boost-reset {
    width: 20%;
}

.market-boost-reset button {
    height: 4vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8vw;
}

.market-filter-button {
    width: 100%;
}

.market-sell-container {
    position: sticky;
    left: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 8vh;
    justify-content: center;
    background: var(--bg-linear);
    z-index: 100;
    border-top-left-radius: 5vw;
    border-top-right-radius: 5vw;
    align-items: center;
    margin-top: auto;
    margin-left: 30%;
    margin-right: 30%;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
}

.market-sell-container button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4vh;
    width: 18%
}

.market-sellbar-container {
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 4vh;
    justify-content: center;
    align-items: center;
    padding: 1vw;
}

.market-sellbar {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 4vh;
    background: linear-gradient(135deg, rgba(26, 26, 26, 1) 0%, rgba(43, 43, 43, 1) 100%);
    color: white;
    border-radius: 1vw;
    padding: 0.5vw;
    border: var(--maind) 0.1vw solid;
    font-weight: bold;
    margin-left: 0.5vw;
    box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 0.5);
}

.market-sellbar:focus {
    outline: none;
}

.market-sellbar-placeholder {
    color: gray;
}

.market-filter-buttons {
    display: flex;
    flex-direction: row;
    padding: 0.5vw;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.5vw;
}

.market-filter-inner {
    border-radius: 0.5vw;
    background: var(--bg);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    box-shadow: inset 0 0.2vw 0.4vw rgba(0, 0, 0, 1);

    h4 {
        font-size: 1.25vw;
    }

    h6 {
        font-size: 0.825vw;
    }
}