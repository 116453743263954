.card-menu-container {
    position: relative;
    background: var(--bg1);
    display: flex;
    flex-direction: column;
    padding: 0.5vw;
    border-radius: 0.5vw;
    min-height: 50%;
    max-height: 80%;
    min-width: 50%;
    backdrop-filter: blur(0.1vw);
}

.title-card-menu {
    font-family: EA Font;
    color: white;
    font-weight: normal;
    padding: 1vw;
    transition: transform 0.2s ease; /* Add transition for scaling */
}

.subtitle-card-menu {
    
    align-items: center;
    left: 37%;
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    bottom: -2vw;
    text-align: center;
    font-size: 1.67vw;
    font-weight: bold;
    margin-top: 0.5vw;

    p {
        margin-left: 0.25vw;
        margin-top: 0.4vw;
        margin-bottom: 0.55vw;
    }

}

.card-menu-content {
    position: relative;
    border-radius: 0.5vw;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: var(--bg);
    padding: 1vw;
    min-width: 50%;
    height: 100%;
    overflow: hidden;
    box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 1);
}

.card-menu-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(rgba(0, 0, 0, .6) 30%, rgba(0, 0, 0, 0) 70%);
    width: 20vw;
    height: 10vw;
    margin: 0.5vw;
    position: relative;
}