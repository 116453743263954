.bronze-pack {
    background: var(--bronze-gradient) !important;
    --color1: var(--bronze1);
    --color2: var(--bronze2);
    --color3: var(--bronze3);
    --color4: var(--bronze4);
    --color-gradient: var(--bronze-gradient);
}

.silver-pack {
    background: var(--silver-gradient) !important;
    --color1: var(--silver1);
    --color2: var(--silver2);
    --color3: var(--silver3);
    --color4: var(--silver4);
    --color-gradient: var(--silver-gradient);
}

.gold-pack {
    background: var(--gold-gradient) !important;
    --color1: var(--gold1);
    --color2: var(--gold2);
    --color3: var(--gold3);
    --color4: var(--gold4);
    --color-gradient: var(--gold-gradient);
}

.legendary-pack {
    background: var(--legendary-gradient) !important;
    --color1: var(--legendary1);
    --color2: var(--legendary2);
    --color3: var(--legendary3);
    --color4: var(--legendary4);
    --color-gradient: var(--legendary-gradient);
}

.pack-container {
    background: var(--bg-linear);
    border-radius: 0.5vw;
    padding: 0.5vw;
    box-sizing: border-box;
    width: 27%;
    cursor: pointer;
    height: 30vw;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    margin: calc((100%/4)/8);
    border: 0.1vw solid #000000;
    box-shadow: 0 0.2vw 1vw rgba(0, 0, 0, 1);
    transition: .5s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.pack-container:hover {
    scale: 1.02;

    .title-pack {
        color: var(--mainb);
    }
}

.pack-container:active {
    scale: 0.98;

    .title-pack {
        color: var(--maind);
    }
}

.title-pack {
    font-family: EA Font;
    color: white;
    font-size: 2vw;
    text-align: center;
    height: 20%;
    margin-bottom: 0.2vw;
}

.pack-bottom {
    position: absolute;
    overflow: hidden;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 46%;
    background: var(--color2);
    background: linear-gradient(135deg, var(--color2) 0%, var(--color4) 100%);

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: -0.1vw;
        width: 100%;
        height: 0.2vw;
        background: var(--color2);
    }

    .name {
        text-align: center;
        font-size: 1.5vw;
        text-transform: uppercase;
        font-weight: 700;
        opacity: 0;
        top: 0.25vw;
        position: relative;
        transition: .5s all ease-out 3.2s;
        margin: 0.02vw 0;
    }

    .stats {
        position: relative;
        margin: 0 1vw;
        padding-top: 0.2vw;
        border-top: 0 solid var(--color2);
        transition: .5s all cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
        

        &:after {
            content: "";
            position: absolute;
            bottom: -0.6vw;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 0.2vw;
            width: 0;
            background: var(--color2);
            transition: .5s all cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
        }

        div {
            width: 98%;
            vertical-align: top;
            display: inline-block;
            text-transform: uppercase;
            font-size: 1.2vw;

            &:last-child {
                ul {
                    margin-left: 1.2vw;
                }
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin: 0;
                position: relative;
                padding: 0;
                display: block;
                margin-bottom: 0.25vw;
                transition: .5s all cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
                opacity: 1;
                left: 1vw;
                height: 1.2vw !important;

                &:nth-child(2) {
                    transition-delay: 2.2s;
                }

                &:nth-child(3) {
                    transition-delay: 2.4s;
                }

                &:nth-child(4) {
                    transition-delay: 2.6s;
                }

                &:nth-child(5) {
                    transition-delay: 2.8s;
                }

                &:nth-child(6) {
                    transition-delay: 3s;
                }

                span {
                    position: relative;
                    display: inline-block;
                    font-weight: 700;
                    width: 49%;
                    text-align: right;
                    font-size: 1vw;

                    &:first-child {
                        font-weight: 700;
                        width: 49%;
                        text-align: left;
                    }
                }
            }
        }
    }
}

.pack-bottom {
    .stats {
        transition: none;
        border-top: 0.2vw solid var(--color2);
        transition: none !important;

        &:after {
            width: 25%;
            transition: none;
        }

        &:before {
            height: 90%;
            transition: none;
        }

        ul {
            li {
                opacity: 1;
                left: 0;
                top: 0;
                margin: 0%;
                transition: none !important;
            }
        }
    }

    .name {
        opacity: 1;
        top: 0;
        transition: none !important;

    }
}