.event-page-container {
    margin-bottom: 0.5vw;
    background: var(--bg-linear);
    border-radius: 0.5vw;
    padding: 0.5vw;
    display: flex;
    flex-direction: column;
    /* Stack elements vertically */
    align-items: flex-start;
    /* Align items to the start (left) */
}

.eventpage-info {
    display: flex;
    flex-direction: row;
    /* Ensure items are in a row */
    flex-wrap: nowrap;
    align-items: center;
    /* Align items vertically centered */
    width: 100%;
    /* Ensure it takes full width */
}

.title-eventpage {
    font-size: 2vw;
    font-family: EA Font;
    color: white;
    font-weight: normal;
    margin-right: 0.5vw;
    width: 100%;
    margin: 0;
}

.text-eventpage {
    font-family: EA Font;
    color: white;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.eventpage-timer-container {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.timer-eventpage {
    font-size: 1.1vw;
    font-family: EA Font;
    color: white;
    font-weight: normal;
    display: flex;
    margin: 0;
    /* Add some space below the text */
    align-self: center;
    /* Align to the start (left) */
}

.event-teams-container {
    display: flex;
    flex-direction: column;

    p {
        font-size: 1vw;
        font-weight: 500;
        margin-bottom: 0;
    }
}

.event-teams {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0.5vw;
    background: var(--bg);
    border-radius: 0.5vw;
    padding: 0.5vw;
    box-shadow: inset 0 0.2vw 0.4vw rgba(0, 0, 0, 1);
    min-width: calc(50% - 0.25vw);
}

.eventpage-image {
    display: flex;
    border-radius: 0.5vw;
    margin-right: 0.75vw;
    width: 2.5vw;
    height: 2.5vw;
}

.upcoming-eventpage {
    display: flex;
    position: relative;
    background-color: var(--maindd);
    padding: 0.12vw 0.25vw;
    border-radius: 0.5vw;
    align-self: center;
}

.active-eventpage {
    display: flex;
    position: relative;
    background-color: var(--mainb);
    padding: 0.12vw 0.25vw;
    border-radius: 0.5vw;
    align-self: center;
}

.live-eventpage {
    display: flex;
    position: relative;
    background-color: red;
    padding: 0.12vw 0.25vw;
    border-radius: 0.5vw;
    align-self: center;
}

.eventpage-desc-container {
    display: flex;
    flex-direction: column;
    background: var(--bg);
    padding: 0.5vw;
    width: 100%;
    border-radius: 0.5vw;
    box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 1);
}

.eventpage-desc {
    color: white;
    font-weight: normal;
    width: 100%;
}

.eventpage-desc-table-container {
    display: flex;
    flex-direction: row;
    /* Ensure items are in a row */
    flex-wrap: nowrap;
    /* Ensure items do not wrap */
    justify-content: space-between;
    margin-bottom: 0.5vw;
}

.eventpage-desc-table-container td {
    padding: 0.2vw 0.5vw;
}

.eventpage-desc-table {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    border-radius: 0.5vw;
    box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 1);
    backdrop-filter: blur(0.1vw);
    background: var(--bg);
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
}

.eventpage-desc-table th,
.eventpage-desc-table td {
    font-size: 0.9vw;
    display: flex;
    position: relative;
    flex-direction: column;
    width: 50%;
    padding: 0;
    margin: 0;
    flex-wrap: nowrap;
    border-bottom: 0.1vw solid var(--mainb);
}

.eventpage-desc-table td:last-child {
    width: 50%;
}

.eventpage-desc-table tr {
    display: flex;
    flex-direction: row;
}

.eventpage-desc-table tr:last-child {
    border-bottom: none;
}

.eventpage-desc-table th {
    color: white;
    font-weight: bold;
    font-size: 1.4vw;
}



.eventpage-desc-table:first-of-type {
    margin-right: 0.25vw;
}

.eventpage-desc-table:last-of-type {
    margin-left: 0.25vw;
}

.eventpage-team {
    display: flex;
    position: relative;
    width: 2.5vw;
    height: 2.5vw;
    background: radial-gradient(circle, 
                rgba(0, 0, 0, 0.5) 0%, 
                rgba(0, 0, 0, 0.3) 40%, 
                rgba(0, 0, 0, 0) 80%);
    justify-content: center;
    align-items: center;
}

.eventpage-team-image {
    display: flex;
    object-fit: cover;
    width: 80%;
    height: 80%;
    position: relative;
}


.eventpage-selct-container {
    display: flex;
    flex-direction: row;
    /* Ensure items are in a row */
    flex-wrap: wrap;
    /* Allow items to wrap to the next line */
    justify-content: flex-start;
    /* Align items to the start (left) */
    margin-top: 0.5vw;
    /* Add some space above the container */
    gap: 0.5vw;
    /* Add some space between items */
}

.event-fantasy-team-container {
    display: flex;
    flex-direction: row;
    /* Ensure items are in a row */
    align-items: center;
    /* Align items vertically centered */
    border-radius: 0.5vw;
    padding: 0.25vw 0.5vw;
    /* Add some padding */
    font-weight: normal;
    width: 100%;
    /* Ensure it takes full width */
    gap: auto;
    /* Add some space between items */
    min-height: 22.7vw;
}

.event-fantasy-player {
    flex: 1 1 20%;
    /* Each card takes up 20% of the container width */
    margin: 0.5vw;
    display: flex;
    flex-direction: row;
    /* Ensure items are in a row */
    align-items: center;
    justify-content: center;
    /* Align items vertically centered */
    border-radius: 0.5vw;
    font-weight: normal;
    cursor: pointer;
    width: calc(20% - 1.5vw);
    /* Set the width to 50% minus the gap */
    margin: auto;
    /* Add some space to the right */
    transform: scale(var(--scale-factor, 1));
    /* Use CSS variable for scaling */
}

.fantasy-player-image {
    object-fit: cover;
    /* Ensure the image maintains its aspect ratio */
    width: 100%;
    /* Set the width to 100% */
}

.submit-team-button {

    margin-top: 0.5vw;
}

.event-page-inventory-container {
    width: 100%;
    height: auto;
    /* Ensure it can expand to fit content */
    overflow: visible;
    /* Ensure no scrolling */
    display: flex;
    /* Add flex display */
    flex-direction: column;
    /* Stack elements vertically */
    align-items: stretch;
    /* Ensure children take full width */
}

.event-page-team-container {
    margin-bottom: 0.5vw;
    background: radial-gradient(rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 100%);
    border-radius: 0.5vw;
    padding: 0.5vw;
    display: flex;
    flex-direction: column;
    /* Stack elements vertically */
    align-items: center;
    /* Align items to the start (left) */
    height: fit-content;
    box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 1);
    backdrop-filter: blur(0.1vw);
}

.card-point-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-points {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0;
    text-align: center;
    align-content: center;
    font-weight: bolder;
    text-align: center;
    align-content: center;
    font-weight: bolder;
}

.event-points-box {
    font-size: 0.83vw;
    position: relative;
    bottom: 0.5vw;
    text-align: left;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    width: 100%;
    margin-top: 0.5vw;
    background-color: rgba(0, 0, 0, 0.6);
    border: var(--maindd) solid 0.1vw;
    color: white;
    border-radius: 0.5vw;
    box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 0.5);
    box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 1);
    justify-content: center;
    align-items: center;
}

.event-teampoints-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin-top: 0.5vw;
    gap: 0.5vw;
    width: 100%;
}

.event-points-box.total-points {
    width: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    margin: 0;
    text-align: center;
    align-content: center;
    font-weight: bolder;
    text-align: center;
    align-content: center;
    font-weight: bolder;

    p {
        font-size: 1.67vw;
        margin: 0;
    }
}

.points {
    width: 100%;
    height: 3vw;
    background: radial-gradient(rgba(0, 0, 0, .5) 40%, rgba(0, 0, 0, 0) 70%);
    text-align: center;
    align-content: center;
    font-weight: bolder;
}