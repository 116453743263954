.newsSide-container {
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--bg1);
    border-radius: 0.5vw;
    padding: 0.5vw;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
    backdrop-filter: blur(2px);
    height: 100%;
    overflow: hidden;
    width: 90%;
}

.news {
    display: flex;
    flex-wrap: nowrap;
    padding: 0.5vw;
    padding-left: 0.25vw;
    padding-right: 0.25vw;
    width: 100%;
    border-top: 0.1vw transparent solid;
    border-bottom: var(--bg1) 0.1vw solid;
    border-color: var(--bg1);
    border-width: 0.1vw;
    cursor: pointer;
    overflow: hidden;
}

.news:hover {
    background-color: var(--bg1);
    color: var(--main);
    border-radius: 0.5vw;
    transform: scale(1.04);
    transition: 0.2s;
    box-shadow: 0 0 0.2vw var(--mainb);
    border: 0.1vw solid var(--main);
}

.news:hover .news-name {
    transition: 0.2s;
    color: var(--main);
}

.news-flag {
    width: 1.5vw;
    height: auto;
    margin-right: 0.5vw;
    justify-self: center;
    align-self: center;
    border: #000000 0.1vw solid;
}

.newsBox {
    flex: 1; /* Allow the newsBox to grow and fill the available space */

    display: flex;
    position: relative;
    background-color: var(--bg);
    border-radius: 0.5vw;
    padding: 0.5vw;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: inset 0 0.2vw 0.4vw rgba(0, 0, 0, 1);
    scroll-behavior: smooth; /* Add smooth scrolling */
}

.title-news {
    font-family: EA Font;
    font-size: 2vw;
    color: white;
    font-weight: normal;
    margin: 0;
}

.news-name {
    font-size: 0.9vw;
    color: white;
    margin: 0;
}