.inbox-container {
    position: relative;
    display: inline-block;
    z-index: 10000;
    margin: 0;
    padding: 0;
    height: 100%;
}

.notification-icon {
    display: flex;
    width: 1.2vw;
    height: auto;
}
.notification-icon.active {
    fill: var(--mainb);
    filter: url(#glow);
}

.notification-icon:hover {
    fill: var(--main);
    cursor: pointer;
}

.notification-icon:active {
    transform: scale(0.95);
    fill: var(--maindd);
}

.notification-icon:focus {
    fill: var(--main);
}

.inbox-dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: var(--bg1);
    width: 19.45vw;
    height: 40vh;
    box-shadow: 0 0.4vw 1vw 0 rgba(0,0,0,1);
    border-radius: 0.5vw;
    padding: 0.5vw;
    right: -50%;
    top: 188%; /* Adjusted to make space for the arrow */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    backdrop-filter: blur(0.1vw);
    z-index: -10000;
}

.inbox-dropdown::before {
    content: "";
    position: absolute;
    top: -1.5vw; /* Position the arrow above the dropdown */
    right: 0.5vw;
    border-width: 0.8vw;
    border-style: solid;
    border-color: transparent transparent var(--bg1) transparent;
    z-index: -1;
    height: 0;
}

.inbox-inner {
    display: flex;
    position: relative;
    background: var(--bg);
    box-shadow: inset 0 0.2vw 0.5vw 0 rgba(0,0,0,1);
    height: 100%;
    font-size: 0.9vw;
    border-radius: 0.5vw;
    overflow-y: auto; /* Add scroll if content overflows */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    overflow-y: auto;
    overflow-x: hidden;
}

.inbox-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inbox-navbar p{
    padding: 1%;
}

.inbox-dropdown h3 {
    margin: 0;
    font-family: EA Font;
    font-size: 1.8vw;
    color: var(--main);
    width: auto;
}

.inbox-clearall {
    font-size: small;
    cursor: pointer;
    justify-self: center;
    padding: 0;
}

.inbox-clearall:hover {
    color: var(--mainb);
}

.inbox-clearall:active {
    color: var(--maind);
    transform: scale(0.95);
}

.inbox-dropdown h4 {
    margin: 0;
    width: 100%;
    height: 100%;
}

.inbox-no-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1vw;
}

.inbox-inner p {
    margin: 0;
    padding: 0;
    font-size: 0.8vw;
    color: var(--main);
}

.inbox-dropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.inbox-dropdown ul li {
    padding: 0.4vw 0.6vw;
    border-bottom: 0.1vw solid var(--bg2);
}

.inbox-dropdown ul li:last-child {
    border-bottom: none;
}

.inbox-dropdown ul li:hover {
    background-color: var(--maintransparent);
    cursor: pointer;
}