.howtoplay-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5vw;
    width: 100%;
    height: 100%;
    background: var(--bg);
    border-radius: 0.5vw;
    backdrop-filter: blur(0.1vw);
    box-shadow: inset 0 0 0.5vw #000000;
    overflow-y: auto;
    overflow-x: hidden;
}

.howtoplay-nav-inner button {
    font-size: 1vw;
    width: 100%;
    margin-top: 0.5vw;
}

.howtoplay-nav-inner button:first-child {
    margin-top: 0;
}

.howtoplay-container {
    display: flex;
    flex-direction: column;
    position: sticky;
    /* Make it sticky */
    top: 4vw;
    /* Stick to the top of the container */
    width: calc((100%/24)*19);
    height: 100%;
    background: var(--bg-linear);
    border-radius: 0.5vw;
    padding: 0.5vw;
    overflow-y: auto;
    overflow-x: hidden;
    backdrop-filter: blur(0.1vw);
}

.howtoplay-content {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    overflow-y: auto !important;
    overflow-x: hidden;
    background: var(--bg);
    border-radius: 0.5vw;
    padding: 1vw;
    margin: 0;
    backdrop-filter: blur(0.1vw);
    box-shadow: inset 0 0 0.5vw #000000;
}

.howtoplay-paragraph {
    width: 100%;
    display: flex; /* Use flexbox to control the layout */
    flex-direction: column; /* Ensure content is stacked vertically */
}

.howtoplay-content h2 {
    width: 100%;
    margin-bottom: 0.5vw;
    font-weight: bolder;
    font-size: 2vw;
}

.howtoplay-content p {
    width: 100%;
    font-size: 1.2vw;
    margin: 0;
}

.howtoplay-content hr {
    width: 100%;
    border: 0;
    height: 0.1vw;
    background: var(--maind);
    margin: 1.5vw 0;
}

.howtoplay-table {
    width: 100%;
    background: var(--bg1);
    border: 0.1vw solid var(--maindd);
    border-collapse: separate;
    /* Ensure border-radius works */
    border-spacing: 0;
    /* Remove spacing between cells */
    border-radius: 0.5vw;
    overflow: hidden;
    /* Ensure border-radius is applied */
}

.howtoplay-table th,
.howtoplay-table td {
    padding: 0.5vw;
    text-align: left;
    border-bottom: 0.1vw solid var(--maindd);
}

.howtoplay-table th:first-child,
.howtoplay-table td:first-child {
    border-top-left-radius: 0.5vw;
}

.howtoplay-table th:last-child,
.howtoplay-table td:last-child {
    border-top-right-radius: 0.5vw;
}