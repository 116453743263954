:root {
    --bronze1: #a97142;
    --bronze2: #d4a07f;
    --bronze3: #5a3a1c;
    --bronze4: #8c6a4a;
    --bronze-gradient: linear-gradient(135deg, var(--bronze2) 0%, var(--bronze4) 100%);
    --silver1: #dfdbd3;
    --silver2: #e5e4e1;
    --silver3: #575756;
    --silver4: #aaa598;
    --silver-gradient: linear-gradient(135deg, var(--silver2) 0%, var(--silver4) 100%);
    --gold1: #e1c072;
    --gold2: #fdeaa7;
    --gold3: #725b16;
    --gold4: #8b6f2e;
    --gold-gradient: linear-gradient(135deg, var(--gold2) 0%, var(--gold4) 100%);
    --legendary1: #171616;
    --legendary2: #363535;
    --legendary3: #a27713;
    --legendary4: #000;
    --legendary-gradient: linear-gradient(135deg, var(--legendary2) 0%, var(--legendary4) 100%);
    --color1: #e1c072;
    --color2: #fdeaa7;
    --color3: #725b16;
    --color4: #aa8d48;
    --color-gradient: linear-gradient(135deg, var(--color2) 0%, var(--color4) 100%);
}
.cardDisplay-container {
    display: flex;
    position: relative;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
    border-radius: 0.5vw;
    padding: 0.5vw;
    width: 25%;
    height: fit-content;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 0.1vw solid rgba(0, 0, 0, 0);
}
.card-container {
    position: relative;
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto Condensed", sans-serif;
}
.bronzeCard {
    --color1: var(--bronze1);
    --color2: var(--bronze2);
    --color3: var(--bronze3);
    --color4: var(--bronze4);
    --color-gradient: var(--bronze-gradient);
}
.silverCard {
    --color1: var(--silver1);
    --color2: var(--silver2);
    --color3: var(--silver3);
    --color4: var(--silver4);
    --color-gradient: var(--silver-gradient);
}
.goldCard {
    --color1: var(--gold1);
    --color2: var(--gold2);
    --color3: var(--gold3);
    --color4: var(--gold4);
    --color-gradient: var(--gold-gradient);
}
.legendaryCard {
    --color1: var(--legendary1);
    --color2: var(--legendary2);
    --color3: var(--legendary3);
    --color4: var(--legendary4);
    --color-gradient: var(--legendary-gradient);
}
.bronzeBoost {
    --color1: var(--bronze1);
}
.silverBoost {
    --color1: var(--silver1);
}
.goldBoost {
    --color1: var(--gold1);
}
.card-container.small {
    transform: scale(0.8);
    transform-origin: center;
}
.card-container li {
    font-size: 1vw;
}
.statText {
    font-size: 0.7vw;
}
.club * {
    font-size: small !important;
    font-weight: bolder;
    justify-content: center !important;
    display: flex !important;
    text-align: center !important;
}
.card-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 1vw;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.3s ease, transform 0.3s ease;
    height: 100%;
    box-sizing: border-box;
}
.market .card-info {
    opacity: 1;
    transform: translateY(0);
}
.card-info ul {
    padding-left: 0;
    margin-left: 0;
}
#card:hover .card-info {
    opacity: 1;
    transform: translateY(0);
}
.boost-name {
    font-weight: bold;
    font-size: 0.9vw;
    margin-right: 0.5vw;
}
.boost-value {
    font-weight: bold;
    font-size: 1vw;
    margin-left: 0.5vw;
}
#card {
    position: relative;
    color: var(--color3);
    aspect-ratio: 0.63 / 1;
    width: 14vw;
    margin: auto;
    clip-path: url("#svgPath");
    display: block;
    opacity: 0;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: rotateY(-180deg);
    transition-delay: 0.1s;
}
#card #card-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
#card #card-inner #card-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 54%;
    background: var(--color1);
    overflow: hidden;
    background: linear-gradient(135deg, var(--color2) 0%, var(--color4) 100%);
}
#card #card-inner #card-top .backfont {
    position: absolute;
    bottom: -1.1vw;
    line-height: 1;
    font-size: 5.9vw;
    left: 0;
    letter-spacing: -0.25vw;
    opacity: 0;
    font-weight: 700;
    font-style: italic;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1) 0.4s;
}
#card #card-inner #card-top .image {
    position: absolute;
    right: 0.5vw;
    bottom: 0;
    z-index: 2;
    height: 75%;
    width: 70%;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0;
    transition: 0.5s all ease-out 3s;
}
#card #card-inner #card-top .info {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 3;
    height: 75%;
    width: 30%;
    box-sizing: border-box;
    padding: 0 1vw;
    text-align: center;
    text-transform: uppercase;
}
#card #card-inner #card-top .info div {
    position: relative;
    line-height: 1;
    letter-spacing: -0.1vw;
    font-size: 1vw;
    opacity: 0;
    top: 1vw;
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}
#card #card-inner #card-top .info div.value {
    font-size: 2vw;
}
#card #card-inner #card-top .info div.position {
    transition-delay: 1.25s;
}
#card #card-inner #card-top .info div.country {
    transition-delay: 1.5s;
}
#card #card-inner #card-top .info div.club {
    transition-delay: 1.75s;
}
#card #card-inner #card-top .info div.position, #card #card-inner #card-top .info div.country {
    padding-bottom: 0.25vw;
    font-weight: 700;
    margin-bottom: 0.25vw;
}
#card #card-inner #card-top .info div.country, #card #card-inner #card-top .info div.club {
    position: relative;
    display: flex;
    width: 2vw;
    height: 1.25vw;
    margin: 1.25vw auto;
    justify-self: center;
    align-self: center;
    justify-content: center;
    text-align: center;
}
#card #card-inner #card-top .info div.country div, #card #card-inner #card-top .info div.club div {
    position: relative;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
}
#card #card-inner #card-top .info div.country.club, #card #card-inner #card-top .info div.club.club {
    height: 3vw;
}
#card #card-inner #card-top .info div.country.club div, #card #card-inner #card-top .info div.club.club div {
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
}
#card #card-inner #card-bottom {
    position: absolute;
    overflow: hidden;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 46%;
    background: var(--color2);
    background: linear-gradient(135deg, var(--color2) 0%, var(--color4) 100%);
}
#card #card-inner #card-bottom:before {
    content: "";
    position: absolute;
    left: 0;
    top: -0.1vw;
    width: 100%;
    height: 0.2vw;
    background: var(--color2);
}
#card #card-inner #card-bottom .name {
    text-align: center;
    font-size: 1.5vw;
    text-transform: uppercase;
    font-weight: 700;
    opacity: 0;
    top: -0.25vw;
    position: relative;
    transition: 0.5s all ease-out 3.2s;
    margin: 0.25vw 0;
}
#card #card-inner #card-bottom .stats {
    height: 4.5vw;
    position: relative;
    margin: 0 0.5vw;
    border-top: 0vw solid var(--color2);
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}
#card #card-inner #card-bottom .stats:after {
    content: "";
    position: absolute;
    bottom: -0.3vw;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 0.2vw;
    width: 0;
    background: var(--color2);
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}
#card #card-inner #card-bottom .stats:before {
    content: "";
    position: absolute;
    bottom: -0.45vw;
    left: 0;
    right: 0;
    margin: 0.65vw auto;
    height: 0;
    width: 0.2vw;
    background: var(--color2);
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}
#card #card-inner #card-bottom .stats div {
    width: 49%;
    vertical-align: top;
    display: inline-block;
    text-transform: uppercase;
    font-size: 1.1vw;
}
#card #card-inner #card-bottom .stats div:last-child ul {
    margin-left: 1.2vw;
}
#card #card-inner #card-bottom .stats ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
#card #card-inner #card-bottom .stats ul li {
    margin: 0;
    position: relative;
    padding: 0;
    display: block;
    margin-bottom: 0.25vw;
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
    opacity: 0;
    left: 1vw;
}
#card #card-inner #card-bottom .stats ul li:nth-child(2) {
    transition-delay: 2.2s;
}
#card #card-inner #card-bottom .stats ul li:nth-child(3) {
    transition-delay: 2.4s;
}
#card #card-inner #card-bottom .stats ul li:nth-child(4) {
    transition-delay: 2.6s;
}
#card #card-inner #card-bottom .stats ul li:nth-child(5) {
    transition-delay: 2.8s;
}
#card #card-inner #card-bottom .stats ul li:nth-child(6) {
    transition-delay: 3s;
}
#card #card-inner #card-bottom .stats ul li span {
    position: relative;
    display: inline-block;
}
#card #card-inner #card-bottom .stats ul li span:first-child {
    font-weight: 700;
    width: 1.6vw;
    text-align: left;
}
#card.active {
    display: block;
    cursor: pointer;
    transform: rotate(0deg);
    opacity: 1;
}
#card.active #card-inner #card-top .image {
    right: 0;
    opacity: 1;
}
#card.active #card-inner #card-top .backfont {
    opacity: 0.1;
    left: -0.4vw;
}
#card.active #card-inner #card-top .info div {
    opacity: 1;
    top: 0;
}
#card.active #card-inner #card-bottom .stats {
    border-top: 0.2vw solid var(--color2);
}
#card.active #card-inner #card-bottom .stats:after {
    bottom: -1vw;
    width: 25%;
}
#card.active #card-inner #card-bottom .stats:before {
    top: 0;
    height: 4vw;
}
#card.active #card-inner #card-bottom .stats ul li {
    opacity: 1;
    left: 0;
    top: 0;
    margin: 0%;
}
#card.active #card-inner #card-bottom .name {
    opacity: 1;
    top: 0;
}
#card.static {
    display: block;
    cursor: pointer;
    transform: rotate(0deg);
    transition: none !important;
    opacity: 1;
}
#card.static #card-inner #card-top .image {
    right: 0;
    opacity: 1;
    transition: none !important;
}
#card.static #card-inner #card-top .backfont {
    opacity: 0.1;
    left: -0.4vw;
    transition: none !important;
}
#card.static #card-inner #card-top .info div {
    opacity: 1;
    top: 0;
    transition: none !important;
}
#card.static #card-inner #card-bottom .stats {
    transition: none;
    border-top: 0.2vw solid var(--color2);
    transition: none !important;
}
#card.static #card-inner #card-bottom .stats:after {
    bottom: -1vw;
    width: 5.5vw;
    height: 0.2vw;
    transition: none;
}
#card.static #card-inner #card-bottom .stats:before {
    top: 0;
    height: 4vw;
    width: 0.2vw;
    transition: none;
}
#card.static #card-inner #card-bottom .stats ul li {
    opacity: 1;
    left: 0;
    top: 0;
    margin: 0%;
    transition: none !important;
}
#card.static #card-inner #card-bottom .name {
    opacity: 1;
    top: 0;
    transition: none !important;
}
#card.market {
    display: block;
    cursor: pointer;
    transform: rotate(0deg);
    transition: none !important;
    opacity: 1;
}
#card.market #card-inner #card-top .image {
    right: 0;
    opacity: 1;
    transition: none !important;
}
#card.market #card-inner #card-top .backfont {
    opacity: 0.1;
    left: -0.4vw;
    transition: none !important;
}
#card.market #card-inner #card-top .info div {
    opacity: 1;
    top: 0;
    transition: none !important;
}
#card.market #card-inner #card-bottom .stats {
    transition: none;
    border-top: 0.2vw solid var(--color2);
    transition: none !important;
    opacity: 0;
}
#card.market #card-inner #card-bottom .stats:after {
    width: 25%;
    transition: none;
    opacity: 0;
}
#card.market #card-inner #card-bottom .stats:before {
    top: 0;
    height: 4vw;
    width: 0.2vw;
    transition: none;
    opacity: 0;
}
#card.market #card-inner #card-bottom .stats ul li {
    opacity: 0;
    left: 0;
    top: 0;
    margin: 0%;
    transition: none !important;
}
#card.market #card-inner #card-bottom .name {
    opacity: 0;
    top: 0;
    transition: none !important;
}
.boost-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25vw;
   /* Adjust as needed */
}
.boost-name-container {
    display: flex;
    align-items: center;
}
.svg-container {
    display: flex;
    align-items: center;
    margin-right: 0.25vw;
   /* Adjust as needed */
}
.boost-value {
    margin-left: auto;
   /* Push the boost value to the right */
}
