.navbar {
  height: 100%;
  display: flex;
  justify-content: space-between; /* Ensure space between links and user info */
  flex-wrap: nowrap;
  background: var(--bg);
  position: relative; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  z-index: 99999; /* Make sure it stays on top */
  margin: auto;
  box-shadow: 0 0.25vw 1vw rgba(0, 0, 0, 1);
}

nav.navbar {
  padding: 0;
  display: flex;
  justify-content: space-between; /* Ensure space between links and user info */
  border-radius: 0.5vw;
}

.sticky {
  position: sticky;
  top: 0;
}

/* Links inside the navbar */
.navbar a {
  display: block;
  color: #ffffff;
  text-align: center;
  padding: 0.8vw 1vw;
  text-decoration: none;
  margin: 0;
  border-radius: 0.5vw;
  border: 0.1vw transparent solid;
  font-size: 1vw;
}

/* Change background on mouse-over */
.navbar a:hover {
  background: var(--bg1);
  color: var(--main);
  border: 0.1vw solid var(--main);
  box-shadow: 0 0 0.2vw var(--mainb);
}

.navbar p {
  display: flex; /* Use flexbox */
  align-items: center; /* Center items vertically */
  color: #ffffff;
  text-align: center;
  padding: 0.8vw 1vw;
  text-decoration: none;
  margin: 0;
  border-radius: 0.5vw;
  font-size: 0.9vw;
}

.navbar p svg {
  margin-top: 0.1vw; /* Center the icon vertically */
  margin-right: 0.2vw; /* Add some space between the icon and the text */
  margin-left: 0.2vw; /* Add some space between the icon and the text */
}

/* Main content */
.main {
  margin-top: 1.5vw; /* Add a top margin to avoid content overlay */
}

div.close {
  padding: 0;
  font-family: EA Font;
  color: white;
  font-weight: normal;
}

/* Styles for navbar links and user info */
.navbar-links {
  display: flex;
  flex: 1;
}

.navbar-user-info {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}

.navbar-user-info > div {
  display: flex;
  align-items: center; /* Center CoinIcon and coins text vertically */
  font-weight: bolder;
}

.header-current-tab {
  color: var(--main) !important;
}