.overlay-change-orientation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
  
  .overlay-change-orientation-content {
    text-align: center;
    font-size: 3.5vw;
    font-weight: bold;
    padding: 2vw;
    margin: calc(100vw / 12);
    background: var(--bg);
    border-radius: 1vw;
    border: 0.25vw solid var(--maindd);
    box-shadow: inset 0 0.5vw 1vw rgba(0, 0, 0, 1);
    p {
      margin: 1vw;
    }
  }