.packPage-container {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 95%;
}

.packPageTitle-container {
    display: flex;
    background: var(--bg-linear);
    border-radius: 0.5vw;
    padding: 0.5vw;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
    backdrop-filter: blur(0.1vw);
}

.packPage-packs-container {
    display: flex;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    flex-wrap: wrap;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: radial-gradient(rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 100%);
    border-radius: 0.5vw;
    padding: 0.5vw;
    box-shadow: inset 0 0 0.5vw #000000;
    backdrop-filter: blur(0.1vw);
    margin-top: 0.5vw;
}

.no-packs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 2rem;
    font-weight: bolder;
}

.title-packPage {
    font-family: EA Font;
    color: white;
    font-weight: normal;
    margin: 0;
}

.pack-image {
    width: 13vw;
    object-fit: cover;
    display: flex;
    padding-top: 0.5vw;
    margin: auto;
}

.price-pack {
    color: #ccc;
    font-weight: bold;
    font-size: 0.8vw;
    text-align: center;
    margin-bottom: 0.2vw;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
    border-radius: 0.5vw;
}

.close-button {
    width: 10vw;
    height: 3vw;
    font-size: 1vw;
    padding: 0.5vw 1vw;
}

.SelectPack-container {
    position: relative;
    background: var(--bg-linear);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5vw;
    border-radius: 0.5vw;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
}

.SelectPack-content {
    border-radius: 0.5vw;
    padding: 0.5vw;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    box-shadow: inset 0 0 0.5vw rgba(0, 0, 0, 1);
    border: 0.1vw solid #000000;
}

.pack-tier-container {
    overflow: hidden;
    width: auto;
    height: auto;
    margin: 0.5vw;
    cursor: pointer;
    transition: transform 0.3s;
}

.pack-tier-container:hover {
    transform: scale(1.02);
    transition: transform 0.3s; 
}

.OpenPack-container {
    top: 5vw;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 0.5vw;
    width: calc((100% / 12) * 10);
    height: 100%;
    background:
        linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0) 100%);
}

.OpenPack-content {
    display: flex;
    padding: 0.5vw;
    padding-bottom: 0;
    height: 70vh;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
    mask-image: linear-gradient(to right, transparent, black 10%, rgb(0, 0, 0) 90%, transparent);
    mask-composite: intersect;
    -webkit-mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
    -webkit-mask-composite: intersect;
}

.OpenPack-content::-webkit-scrollbar-track {
    margin-left: 4vw;
    margin-right: 4vw;
}

.OpenPack-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5vw;
    width: 40%;
    justify-content: space-around;
}