.tbc-challenges-main-container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    padding: 0.5vw;
    border-radius: 0.5vw;
    background: var(--bg-linear);
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.5);
    margin-bottom: 0.5vw;
    backdrop-filter: blur(0.1vw);
}

.tbc-challenges-container {
    flex: 1;
    display: flex;
    position: relative;
    background-color: var(--bg);
    border-radius: 0.5vw;
    padding: 0.5vw;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    box-shadow: inset 0 0.2vw 0.4vw rgba(0, 0, 0, 1);
    scroll-behavior: smooth;
    gap: 2vw;
}

.tbc-challenge-main-container {
    .event-page-team-container {
        max-width: 80%;
        min-width: 80%;
    }
}

.tbc-challenge-team-container {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;

    .event-fantasy-player {
        transform: scale(0.85);
    }
}

.circle {
    width: 0.6vw;
    height: 0.6vw;
    border-radius: 100%;
    margin: auto;
    margin-top: 0.1vw;
}

.green {
    background-color: green;
}

.red {
    background-color: red;
}

.tbc-challenge-requirements-container {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 0.5vw;
    border-radius: 0.5vw;
    background: var(--bg1);
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.5);
    margin-bottom: 0.5vw;
    width: 20%;
    margin-right: 0.5vw;
}

.tbc-challenge-requirements {
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: 0.5vw;
    background: var(--bg);
    box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 1);
    height: 100%;
    width: 100%;

    td {
        padding: 0.5vw;
        padding-bottom: 0.5vw;
        border-bottom: 0.1vw solid #ccc;
        font-size: 0.8vw;
    }

    td:first-child {
        padding-right: 0;
    }
}

.tbc-challenge-requirement tbody tr td:last-child {
    text-align: right;
    width: 30%;
}


.tbc-challenges-container::-webkit-scrollbar {
    height: 0.8vw;
}

.tbc-challenge-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    border-right: 0.1vw solid #ccc;
    flex: 1;
    padding-right: 1.5vw;
}

.tbc-challenge-wrapper:first-child {
    margin-left: 1.5vw;
}

.tbc-challenge-wrapper:last-child {
    border-right: 0.1vw solid transparent;
}

.tbc-challenge {
    display: flex;
    flex-direction: column;
    padding: 1vw;
    border-radius: 0.5vw;
    background: var(--bg);
    border: 0.1vw transparent solid;
    height: 100%;
}

.tbc-challenge.completed {
    border: 0.1vw solid black;
    box-shadow: inset 0 0.5vw 1vw rgba(0, 0, 0, 0.6);

    p {
        color: #ccccccab;
    }

    li {
        color: #ccccccab;
    }

    .star {
        color: #cccccc62;
    }

    .star.filled {
        color: #f39d123f;
    }

    .tbc-challenge-name {
        color: #ccccccab;
    }
}

.tbc-challenge p {
    margin-bottom: 0.4vw;
}

.tbc-challenge:hover {
    cursor: pointer;
    background-color: var(--bg1);
    transform: scale(1.03);
    transition: 0.2s;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.7);
    border: 0.1vw solid var(--main);
}

.tbc-challenge.selected {
    border: 0.1vw solid var(--main);
    background: var(--bg1);
    box-shadow: inset 0 0.25vw 0.5vw rgba(0, 0, 0, 0.5);

    .tbc-challenge-name {
        color: var(--main);
    }
}

.tbc-challenge-name {
    font-size: 1.5vw;
    font-weight: bold;
    margin-bottom: 0.5vw;
}

.tbc-challenge-timer {
    font-size: 0.8vw;
    color: #ccc;
}

.tbc-challenge-desc {
    font-size: 1vw;
    color: #ccc;
    span {
        font-weight: bolder;
    }
}

ul.tbc-challenge-desc {
    list-style-type: circle;
    padding-left: 1.3vw;
    margin-bottom: 0;
}

.star {
    width: 1vw;
    height: 100%;
    justify-self: center;
    align-self: center;
    font-size: 1vw;
    color: #ccc;
}

.star.filled {
    color: #f39c12;
}

.tbc-challenge-reward-opened {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;

    h1 {
        font-family: EA Font;
        font-size: 3vw;
    }
}

.tbc-OpenPack-container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(0.1vw);
    width: 100%;
}

.tbc-OpenPack-content {
    display: flex;
    padding: 0.5vw;
    padding-bottom: 0;
    height: 50vh;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
}

.tbc-OpenPack-content::-webkit-scrollbar-track {
    margin-left: 4vw;
    margin-right: 4vw;
}