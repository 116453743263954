.inventory-main-container {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    margin-top: 0.5vw;
    box-sizing: border-box;
}

.inventoryTitle-container {
    background: var(--bg-linear);
    border-radius: 0.5vw;
    padding: 0.5vw;
    display: flex;
    flex-wrap: nowrap;
    position: sticky;
    top: 0;
    z-index: 8099;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
    backdrop-filter: blur(0.1vw);
}

.inventory-box {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.inventory-box-filter {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: flex-end;
}

.inventory-container {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 79%;
    overflow-y: hidden;
    overflow-x: hidden;
    border-radius: 0.5vw;
}

.inventory-locked {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0.5vw;
    /* Semi-transparent background */
    z-index: 2;
    /* Ensure it is in front of other elements */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: white;
    font-size: 2vw;
}

.inventory-locked-text {
    font-family: EA Font;
    color: white;
    display: flex;
    position: sticky;
    top: 0;
    transform: translate(0, 48vh);
    background: radial-gradient(rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 70%);
}

.inventory-main-container {
    display: flex;
}

.inventory-container-fullSize {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-height: 40vw;
    overflow: visible;
    border-radius: 0.5vw;
    padding-left: 0.5vw;
}

.inventory-content {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    overflow-y: auto !important;
    overflow-x: hidden;
    background: radial-gradient(rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 100%);
    border-radius: 0.5vw;
    padding: 0.5vw;
    margin: 0;
    backdrop-filter: blur(0.1vw);
    box-shadow: inset 0 0 0.5vw #000000;
}

.title-inventory {
    font-family: EA Font;
    color: white;
    font-weight: normal;
    margin: 0;
    margin-right: 0.5vw;
    font-size: 1.7vw;
}

.text-inventory {
    font-family: EA Font;
    color: white;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.text-inventory:hover {
    color: var(--main);
}

.text-inventory p {
    height: auto;
    width: auto;
    margin: auto;
    padding-right: 0.5vw;
    padding-left: 0.5vw;
}

.sort-container {
    font-family: EA Font;
    color: white;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    float: right;

}

.sort-svg {
    display: flex;
    height: 1.6vw;
    width: 1.6vw;
    margin: auto;
    cursor: pointer;
    fill: var(--main);
}

.sort-desc-svg {
    height: 1.6vw;
    width: 1.6vw;
    margin: auto;
    cursor: pointer;
    fill: var(--main);
    rotate: 180deg;
}

.sort-desc-svg:hover {
    fill: var(--mainb);
}

.sort-desc-svg:active {
    transform: scale(0.99);
    fill: var(--maind);
}

.sort-asc-svg {
    height: 1.6vw;
    width: 1.6vw;
    margin: auto;
    cursor: pointer;
    fill: var(--main);
}

.sort-asc-svg:hover {
    fill: var(--mainb);
}

.sort-asc-svg:active {
    transform: scale(0.99);
    fill: var(--maind);
}

.dropdown {
    display: flex;
    position: relative;
    display: inline-block;
    justify-self: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.5vw;
    z-index: 100;
}

.dropdown-button {
    display: flex;
    text-align: left;
    padding: 0.1vw;
    padding-left: 0.5vw;
    width: 10.5vw;
    background-color: rgba(0, 0, 0, 0);
    border: var(--maindd) solid 0.1vw;
    color: white;
    font-size: 0.83vw;
    cursor: pointer;
    border-radius: 0.5vw;
    box-shadow: inset 0 0.1vw 0.5vw rgba(0, 0, 0, 0.5);
    z-index: 100;
}


.dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--bg1);
    width: 10.5vw;
    z-index: 90;
    border-radius: 0.5vw;
    border: 0.1vw solid var(--maindd);
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
    border-top: 0 solid #000000;
    backdrop-filter: blur(0.1vw);
}

.dropdown-content p {
    color: white;
    padding: 0.4vw 0.5vw;
    margin: 0;
    text-decoration: none;
    display: block;
    cursor: pointer;
    border: 0.1vw solid rgba(0, 0, 0, 0);
    font-size: 0.83vw;
}

.dropdown-content p:hover {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.5vw;
    border: 0.1vw solid var(--bg);
    color: var(--main);
    box-shadow: inset 0 0.1vw 0.5vw rgba(0, 0, 0, 0.5);
}

.dropdown-content p:active {
    transform: scale(0.99);
    background-color: rgba(0, 0, 0, 0.25);
    border: 0.1vw solid var(--bg);
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropdown-button {
    background-color: rgba(0, 0, 0, 0.2);
    color: var(--main);
}

.inventory-card {
    display: flex;
    position: relative;
    border: 0.1vw solid rgb(0, 0, 0, 0);
}

.highlighted-card {
    background-color: var(--maintransparent);
    border-radius: 0.5vw;
    border: 0.1vw solid var(--main);
    box-shadow: 0 0 0.5vw var(--maindd);
    height: auto;
    box-shadow: inset 0 0.5vw 1vw rgba(0, 0, 0, 1);
}

.inventory-sell-container {
    position: sticky;
    bottom: -0.1vw;
    left: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 8vh;
    justify-content: center;
    background: var(--bg-linear);
    z-index: 100;
    border-top-left-radius: 5vw;
    border-top-right-radius: 5vw;
    align-items: center;
    margin-top: auto;
    margin-left: 30%;
    margin-right: 30%;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
}

.inventory-sell-container button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vh;
}

.inventory-button-container {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: center;
    align-items: center;
}

.inventory-sellbar-container {
    display: flex;
    flex-direction: row;
    width: 30%;
    justify-content: center;
    align-items: center;
}

.inventory-sellbar-text {
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    font-size: large;
    align-items: center;
    margin-left: 4%;
    font-size: 1vw;
}