:root {
  --main: #df8f17;
  --maind: #b67412;
  --maindd: #b67412a8;
  --mainb: #ffa31a;
  --maintransparent: #b674126c;
  --bg: #1b1b1b;
  --bg1: #222222ce;
  --bg-linear: linear-gradient(135deg, rgba(37, 37, 37, 0.747) 0%, rgba(26, 26, 26, 0.85) 80%);
  --bg2: #808080;
  --lightgreen: #60ff60;
  --lightred: #ff6060;
  --scrollbar-track: #222222;
  --scrollbar-thumb: #3f3f3f;
  --vh: 1vh;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.app-container {
  margin-top: 0.5vh;
  height: 100%; /* Ensure app-container takes full height */
}

.app-content {
  height: 100%; /* Ensure app-content takes full height */
}

main {
  display: flex;
  position: relative;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border-radius: 0.5vw;
  height: 100%;
}

.app-background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: brightness(0.5);
}

.hoverable {
  cursor: pointer;
}

.main-container {
  display: flex;
  position: relative;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 91.5);
  overflow-x: auto;
  overflow: visible;
  border-radius: 0.5vw;
}

.title-container {
  background: var(--bg-linear);
  border-radius: 0.5vw;
  padding: 0.5vw;
  display: flex;
  flex-wrap: nowrap;
  position: sticky;
  top: 0;
  z-index: 8099;
  box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 1);
  backdrop-filter: blur(0.1vw);
}

.title {
  font-family: EA Font;
  color: white;
  font-weight: normal;
  font-size: 2.1vw;
  margin: 0;
  margin-right: 0.5vw;
}

::-webkit-scrollbar {
  width: 0.6vw;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: 0.4vw;
}

::-webkit-scrollbar-thumb {
  background-color: var(--maindd);
  border-radius: 0.5vw;
  border: 0.1vw solid var(--scrollbar-track);
}

/* Firefox */
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: var(--maindd) var(--scrollbar-track); /* thumb color and track color */
  }
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.loader {
  position: relative;
  justify-self: center;
  align-self: center;
  margin: auto;
  border: 0.5vw solid #ffffff;
  border-top: 0.5vw solid var(--main);
  border-radius: 50%;
  width: 4vw;
  height: 4vw;
  animation: spin 2s linear infinite;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
}

.loader-small {
  position: relative;
  justify-self: center;
  align-self: center;
  margin: 0;
  border: 0.3vw solid #ffffff;
  border-top: 0.3vw solid var(--main);
  border-radius: 50%;
  width: 1.5vw;
  height: 1.5vw;
  animation: spin 2s linear infinite;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
}

.default-button {
  display: flex;
  text-align: center;
  justify-content: center;
  background: var(--main);
  color: white;
  border: solid 0.1vw var(--mainb);
  padding: 0.5vw;
  border-radius: 0.5vw;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
  white-space: nowrap;
  font-size: 0.8vw;
}

.default-button:disabled {
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
  border: solid 0.1vw var(--bg1);
}

.default-button:hover {
  background: var(--mainb);
}

.default-button:active {
  transform: scale(0.99);
  box-shadow: inset 0.1vw 0.1vw 0.5vw #333, 0 0.2vw 1vw rgba(0, 0, 0, 1);
  background: var(--maind);
}

.default-button.active {
  transform: scale(0.99);
  -webkit-box-shadow: inset 0.1vw 0.1vw 0.5vw #333;
  -moz-box-shadow: inset 0.1vw 0.1vw 0.5vw #333;
  box-shadow: inset 0.1vw 0.1vw 0.5vw #333;
  background: var(--maind);
}

.default-button.big {
  display: flex;
  width: 70%;
  height: 70%;
  font-size: 1.5vw;
  justify-content: center;
  align-items: center;
}

.scroll-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0.5vw;
  overflow-y: auto;
  overflow-x: hidden;
}

.view-button-container {
  width: auto;
  display: flex;
  align-items: center;
  margin-left: 0.5vw;
}

.view-button {
  display: flex;
  background: var(--main);
  color: white;
  border: solid 0.1vw var(--mainb);
  border-radius: 0.5vw;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
  margin-right: 0.5vw;
  white-space: nowrap; /* Prevent text from wrapping */
  font-size: .8vw;
  padding: 0;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

.view-button:disabled {
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
  border: solid 0.1vw var(--bg1);
}

.view-button:hover {
  background: var(--mainb);
}

.view-button:active {
  transform: scale(0.99);
  box-shadow: inset 0.1vw 0.1vw 0.5vw #333, 0 0.2vw 0.5vw rgba(0, 0, 0, 1);
  background: var(--maind);
}

.view-button.active {
  transform: scale(0.99);
  -webkit-box-shadow: inset 0.1vw 0.1vw 0.5vw #333;
  -moz-box-shadow: inset 0.1vw 0.1vw 0.5vw #333;
  box-shadow: inset 0.1vw 0.1vw 0.5vw #333;
  background: var(--maind);
}

code {
  color: var(--main) !important;
}