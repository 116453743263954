body {
    background-color: rgb(0, 0, 0);
    color: white;
}

.eventsFrontpage-container {
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--bg1);
    border-radius: 0.5vw;
    padding: 0.5vw;
    height: 45%;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 1);
    backdrop-filter: blur(0.1vw);
}

.events {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--bg);
    box-sizing: border-box;
    padding: 0.5vw;
    cursor: pointer;
    width: 100%;
    position: relative;
    border-radius: 0.5vw;
    border: 0.1vw transparent solid;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
}

.event-frontpage-info {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    font-weight: bolder;
    text-align: center;
}

.events:hover {
    background-color: var(--bg1);
    transform: scale(1.03);
    transition: 0.2s;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.7);
    border: 0.1vw solid var(--main);
}

.event-wrapper {
    display: flex;
    position: relative;
    border-right: 0.1vw solid #ccc;
    flex: 1 1 calc(46.6% - 0.5vw);
    width: calc(46.6% - 0.5vw);
    flex: 0 0 calc(46.6% - 0.5vw);
    padding-right: 2vw;
}

.event-wrapper:last-child {
    border-right: none;
}

.event-wrapper:first-child {
    margin-left: 2vw;
}

.eventsBox {
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 2.5vw;
    background-color: var(--bg);
    border-radius: 0.5vw;
    padding: 0.5vw;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    flex-wrap: nowrap;
    height: 100%;
    position: relative;
    box-shadow: inset 0 0.2vw 0.4vw rgba(0, 0, 0, 1);
    z-index: 2;
}

.title-events {
    font-size: 2vw;
    font-family: EA Font;
    color: white;
    font-weight: normal;
    width: 100%;
    margin: 0;
}

.event-name {
    font-size: 1.2vw;
    font-weight: bold;
    color: white;
    margin-bottom: 0.1vw;
}

.events:hover .event-name {
    transition: 0.2s;
    color: var(--main);
}

.event-date {
    font-size: 0.7vw;
    color: white;
    margin-bottom: 0.5vw;
}

.event-location {
    font-size: 0.7vw;
    color: white;
    margin-bottom: 0.5vw;
}

.event-prizepool {
    font-size: 0.7vw;
    color: white;
    margin-bottom: 0.5vw;
}

.flag {
    width: 1vw;
    height: auto;
    margin-left: 0.2vw;
    border: #000000 0.1vw solid;
}

.moreinfo {
    font-size: 0.7vw;
    color: var(--main);
    text-decoration: underline;
    bottom: 0.1vw;
    position: absolute;
}

.moreinfo:hover {
    color: var(--mainb);
    text-decoration: underline;
}

.event-image {
    width: 2.5vw;
    height: auto;
    margin-right: 0.7vw;
}

.event-details {
    display: flex;
    flex-direction: column;
}

.event-date,
.event-location,
.event-prizepool,
.moreinfo {
    margin: 0.3vw 0;
}

.event-content {
    display: flex;
    /* Make image and details side by side */
    align-items: flex-start;
    /* Align the image and details at the top */
}

.event-info {
    display: flex;
    flex-direction: column;
}

.event-upcoming {
    display: flex;
    position: relative;
    background-color: var(--maindd);
    padding: 0.12vw 0.25vw;
    border-radius: 0.5vw;
    align-self: flex-start;
    margin: 0;
    font-size: 0.9vw;
}

.event-active {
    display: flex;
    position: relative;
    background-color: var(--mainb);
    padding: 0.12vw 0.25vw;
    border-radius: 0.5vw;
    align-self: flex-start;
    margin: 0;
    font-size: 0.9vw;
}

.event-live {
    display: flex;
    position: relative;
    background-color: red;
    padding: 0.12vw 0.25vw;
    border-radius: 0.5vw;
    align-self: flex-start;
    margin: 0;
    font-size: 0.9vw;
}